import {inject, Injectable} from '@angular/core';
import {catchError, combineLatest, map, Observable, of} from 'rxjs';
import {GoodsService} from '@shared/services/api/goods.service';
import {CategoriesService} from '@shared/services/api/categories.service';
import {CollectionsService} from '@shared/services/api/collections.service';
import {Category} from '@shared/models/entities/Category.type';
import {Collection} from '@shared/models/entities/Collection.type';
import {GoodsListItem} from '@shared/models/entities/Goods.type';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private goodsService = inject(GoodsService);
  private categoriesService = inject(CategoriesService);
  private collectionsService = inject(CollectionsService);

  EMPTY_RESULT: SearchResult = {
    category: [],
    collection: [],
    product: []
  }

  constructor() {
  }

  getCombinedObservable(search: string): Observable<SearchResult> {
    return combineLatest([

      this.categoriesService.search({search}).pipe(
        catchError(() => of([])) // Handle errors gracefully
      ),
      this.collectionsService.search({search}).pipe(
        catchError(() => of([])) // Handle errors gracefully
      ),
      this.goodsService.search({
        search: search,
      }).pipe(
        catchError(() => of([])) // Handle errors gracefully
      ),
    ]).pipe(
      map(([category, collection, product]) => ({category, collection, product}))
    )
  }
}

export type SearchResult = {
  category: Category[];
  collection: Collection[];
  product: GoodsListItem[];
};
